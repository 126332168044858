import React from "react";
// import Logo from "../../../../images/logo-smartbip.png";
import Logo from "../../../../images/PAVI-PAMA-LOGO-blue.png";
import HeaderItem from "../HeaderItems/HeaderItem";
import HeaderCartItem from "../HeaderItems/HeaderCartItem";
import config from "../../../../config/config";
import { Link, withRouter } from "react-router-dom"
import { Translation } from 'react-i18next';
import CategorySelect from "../../../CategorySelect/CategorySelect";
import { ReactComponent as UserIcon } from '../../../../images/landing-user-sticky.svg';
import { ReactComponent as CartIcon } from '../../../../images/landing-cart-sticky.svg';
import { ReactComponent as CategoryIcon } from '../../../../images/category.svg';
import { ReactComponent as LeafletsIcon } from '../../../../images/leaflets.svg';
import { ReactComponent as Search } from '../../../../images/search.svg';
import { ReactComponent as Cancel } from '../../../../images/close.svg';
import reactStringReplace from 'react-string-replace';
// import { ReactComponent as PinIcon } from '../../../../images/pin.svg';
// import { ReactComponent as ShopIcon } from '../../../../images/shop.svg';

import "./new-header.styles.scss";

class NewHeader extends React.Component {
    updateCart = () => {
        if (this.props.user && config.SHOW_CART_UPDATE) {
            return (
                <Link to="/cart/update">
                    <nobr>
                        <span className="pin-link">{this.props.cart && this.props.cart.storeDescription ?
                            this.props.cart.storeDescription : config.DEFAULT_STORE_DESCRIPTION}</span>
                    </nobr>
                </Link>
            );
        } else {
            return (
                <nobr>
                    <span className="pin-link">{this.props.cart && this.props.cart.storeDescription ?
                        this.props.cart.storeDescription : config.DEFAULT_STORE_DESCRIPTION}</span>
                </nobr>
            );
        }
    }

    render() {
        const { user, cart, searchInput, onChange, closeAll, showModalLogin, submitSearch, setCategory, handleChangeTips, handleChangeCategories, handleChangeBrands, tips, categories, brands, isOpen, cancelSearchItem, inputFocus } = this.props;
        const inputClass = `${isOpen ? "form-with-two-round-borders" : "form-with-all-round-borders"} `
        const menuClass = `dropdown-items ${isOpen ? "dropdown--isOpen" : ""}
        dropdown-menu${isOpen ? " show" : ""} `;
        return (
            <Translation>
                {t =>
                    <div className="header">
                        <div className="header-wrapper">
                            <div className="container-fluid">
                                <div className="row header-top">
                                    {/* <div className="col pin">
                                        <PinIcon />
                                        {this.updateCart()}
                                    </div>
                                    {user && order &&
                                        <div className="col order">
                                            <nobr><div>
                                                {t("header.yourOrder")}
                                                <Link className="order-link" to="/orders">
                                                    <span> {order && order.code} </span>
                                                </Link>
                                                {t("header.orderNumberIs")} <span className="status">{order && t(`order.status.${order.status}`)}</span>
                                            </div></nobr>
                                        </div>
                                    }
                                    <div className="col shop text-right">
                                        <Link className="link" to="/stores" onClick={closeAll}>
                                            <ShopIcon />
                                            <span className="shop-link">{t("stores")}</span>
                                        </Link>
                                    </div> */}
                                </div>
                                <div className="row header-bottom">
                                    <div className="col-3">
                                        <div className="align-items-center logo">
                                            <Link to="/" onClick={closeAll}>
                                                {/* <Logo /> */}
                                                <img src={Logo} alt="" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-6 search">
                                        <form className={inputClass} onSubmit={e => submitSearch(e)}>
                                            <input
                                                type="search"
                                                onChange={onChange}
                                                value={searchInput}
                                                placeholder={t("header.mobileMobilePlaceHolder")}
                                                ref={inputFocus.ref}
                                                onKeyPress={event => {
                                                    if (event.key === 'Enter') {
                                                        submitSearch(event)
                                                    }
                                                }}
                                            />
                                            {tips &&
                                                <div className={menuClass} aria-labelledby="dropdownMenuButton">
                                                    <div className="tips-items">
                                                        <h5 style={{ color: "grey", paddingLeft: "25px" }}>Suggestions</h5>
                                                        {tips && tips.map((item, index) => (
                                                            <span key={index} className="dropdown-item"
                                                                onClick={() => handleChangeTips(item)}>
                                                                {reactStringReplace(item, `${searchInput}`, (match, i) => (
                                                                    <span key={i} style={{ fontWeight: "bold" }}>{match}</span>
                                                                ))}
                                                            </span>
                                                        ))}

                                                    </div>
                                                    <div className="brands-items">
                                                        <h5 style={{ color: "grey", paddingLeft: "25px" }}>Top Brands</h5>
                                                        {brands && brands.map((item, index) => (
                                                            <span key={index} className="dropdown-item"
                                                                onClick={() => handleChangeBrands(item)}>
                                                                <div style={{ display: "flex" }}>
                                                                    <div>
                                                                        {reactStringReplace(item, `${searchInput}`, (match, i) => (
                                                                            <span key={i} style={{ fontWeight: "bold" }}>{match}</span>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        ))}
                                                    </div>
                                                    <div className="category-items">
                                                        <h5 style={{ color: "grey", paddingLeft: "25px" }}>Top Categories</h5>
                                                        {categories && categories.map((item, index) => (
                                                            <span key={index} className="dropdown-item"
                                                                  onClick={() => handleChangeCategories(item)}>
                                                                <div style={{ display: "flex" }}>
                                                                    <div>
                                                                        {reactStringReplace(item.label, `${searchInput}`, (match, i) => (
                                                                            <span key={i} style={{ fontWeight: "bold" }}>{match.label}</span>
                                                                        ))}
                                                                    </div>
                                                                    <div className="count">{item.count}</div>
                                                                </div>
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            }
                                            {
                                                searchInput &&
                                                <button onClick={(e) => cancelSearchItem(e)} className="button-cancel" >
                                                    <Cancel />
                                                </button>
                                            }
                                            {/* <CategorySelect setCategory={setCategory} /> */}
                                            <button type="submit" className="button-submit">
                                                {/* <span>{t("header.search")}</span> */}
                                                <Search />
                                            </button>
                                        </form>

                                    </div>
                                    <div className="col-3 actions">
                                        <HeaderItem
                                            actived={this.props.history.location.pathname.includes("/leaflets")
                                                && (!this.props.showCategories && !this.props.showLogin)}
                                            onClick={() => {
                                                this.props.history.push("/leaflets")
                                                this.props.closeAll();
                                            }}>
                                            <LeafletsIcon />
                                        </HeaderItem>
                                        <HeaderItem
                                            onClick={this.props.categoriesModal}
                                            actived={this.props.showCategories}>
                                            <CategoryIcon />
                                        </HeaderItem>
                                        {user ?
                                            <HeaderItem
                                                onClick={this.props.loginModal}
                                                actived={this.props.showLogin && !this.props.showCategories}>
                                                <div className="user-logged">
                                                    {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                                                </div>
                                            </HeaderItem>
                                            :
                                            <HeaderItem
                                                onClick={showModalLogin}
                                                actived={this.props.showLogin}>
                                                <UserIcon />
                                            </HeaderItem>
                                        }
                                        {user &&
                                            <HeaderCartItem
                                                actived={!this.props.showCategories && !this.props.showLogin}
                                                path="/cart"
                                                total={this.props.renderTotal(cart)}
                                                items={cart ? cart.itemsCount : "0"}
                                                closeAll={this.props.closeAll}>
                                                <CartIcon />
                                            </HeaderCartItem>
                                        }
                                        {!user &&
                                            <HeaderItem
                                                onClick={showModalLogin}>
                                                <CartIcon />
                                            </HeaderItem>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

export default withRouter(NewHeader);
