import React from "react";
import { Link, withRouter } from "react-router-dom"
import { Translation } from 'react-i18next';
import Logo from "../../../../images/PAVI-PAMA-LOGO-blue.png";
import HeaderItem from "../HeaderItems/HeaderItem";
import HeaderCartItem from "../HeaderItems/HeaderCartItem";
import config from "../../../../config/config";
import { ReactComponent as UserIcon } from '../../../../images/landing-user-sticky.svg';
import { ReactComponent as CartIcon } from '../../../../images/landing-cart-sticky.svg';
import { ReactComponent as CategoryIcon } from '../../../../images/category.svg';
import { ReactComponent as LeafletsIcon } from '../../../../images/leaflets.svg';
import { ReactComponent as Search } from '../../../../images/search.svg';
import { ReactComponent as Cancel } from '../../../../images/close.svg';
import reactStringReplace from 'react-string-replace';
import "./mobile-header.styles.scss";

class MobileHeader extends React.Component {

    updateCart = () => {
        if (this.props.user && config.SHOW_CART_UPDATE) {
            return (
                <Link to="/cart/update">
                    <nobr>
                        <span className="pin-link">{this.props.cart && this.props.cart.storeDescription ?
                            this.props.cart.storeDescription : config.DEFAULT_STORE_DESCRIPTION}</span>
                    </nobr>
                </Link>
            );
        } else {
            return (
                <nobr>
                    <span className="pin-link">{this.props.cart && this.props.cart.storeDescription ?
                        this.props.cart.storeDescription : config.DEFAULT_STORE_DESCRIPTION}</span>
                </nobr>
            );
        }
    }

    render() {
        const { user, cart, searchInput, onChange, closeAll, showModalLogin, handleChangeTips, handleChangeCategories, handleChangeBrands, tips, categories, brands, isOpen, inputFocus, cancelSearchItem } = this.props;
        const inputClass = `${isOpen ? "form-with-two-round-borders" : "form-with-all-round-borders"} `
        const menuClass = `dropdown-items ${isOpen ? "dropdown--isOpen" : ""}
        dropdown-menu${isOpen ? " show" : ""} `;
        return (
            <Translation>
                {t =>
                    <div className="mobile-header">
                        <div className="mobile-header-wrapper">
                            <div className="container-fluid">
                                <div className="row mobile-header-bottom">
                                    <div className="col-4">
                                        <div className="logo align-items-center">
                                            <Link to="" onClick={closeAll}>
                                                <img src={Logo} alt="" />
                                                {/* <Logo /> */}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className="icons">
                                            <HeaderItem
                                                actived={this.props.history.location.pathname.includes("/leaflets")}
                                                onClick={() => this.props.history.push("/leaflets")}>
                                                <LeafletsIcon />
                                            </HeaderItem>
                                            <HeaderItem
                                                onClick={this.props.categoriesModal}
                                                actived={this.props.showCategories}>
                                                <CategoryIcon />
                                            </HeaderItem>
                                            {user ?
                                                <HeaderItem
                                                    onClick={this.props.loginModal}
                                                    actived={this.props.showLogin}>
                                                    <div className="user-logged">
                                                        {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                                                    </div>
                                                </HeaderItem>
                                                :
                                                <HeaderItem
                                                    onClick={showModalLogin}
                                                    actived={this.props.showLogin}>
                                                    <UserIcon />
                                                </HeaderItem>
                                            }
                                            {user &&
                                                <HeaderCartItem
                                                    actived={this.props.showCategories}
                                                    path="/cart"
                                                    total={this.props.renderTotal(cart)}
                                                    items={cart ? cart.itemsCount : "0"}
                                                    closeAll={this.props.closeAll}>
                                                    <CartIcon />
                                                </HeaderCartItem>
                                            }
                                            {!user &&
                                                <HeaderItem
                                                    onClick={showModalLogin}>
                                                    <CartIcon />
                                                </HeaderItem>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {(this.props.location.pathname === "/" ||
                                this.props.location.pathname === "/category" ||
                                this.props.location.pathname === "/search" ||
                                this.props.location.pathname === "/cart" ||
                                this.props.location.pathname.includes("/store") ||
                                this.props.location.pathname.includes("/product")
                            ) &&
                                <div className="mobile-header-search">
                                    <form className={inputClass} onSubmit={e => submitSearch(e)} autoComplete="off">
                                        <input
                                            name="input"
                                            placeholder={t("header.mobileMobilePlaceHolder")}
                                            onChange={onChange}
                                            value={searchInput}
                                            type="search"
                                            ref={inputFocus.ref}
                                            onKeyPress={event => {
                                                if (event.key === 'Enter') {
                                                    submitSearch(event)
                                                }
                                            }}
                                        />
                                        {
                                            tips &&
                                            <div className={menuClass} aria-labelledby="dropdownMenuButton" style={this.props.location.pathname === "/search" ? { top: '67%' } : {}}>
                                                <div className="tips-items">
                                                    <h5 style={{ color: "grey", paddingLeft: "25px" }}>Suggestions</h5>
                                                    {tips && tips.map((item, index) => (
                                                        <span key={index} className="dropdown-item"
                                                            onClick={() => handleChangeTips(item)}>
                                                            {reactStringReplace(item, `${searchInput}`, (match, i) => (
                                                                <span key={i} style={{ fontWeight: "bold" }}>{match}</span>
                                                            ))}
                                                        </span>
                                                    ))}
                                                </div>
                                                <div className="brands-items">
                                                    <h5 style={{ color: "grey", paddingLeft: "25px" }}>Top Brands</h5>
                                                    {brands && brands.map((item, index) => (
                                                        <span key={index} className="dropdown-item"
                                                              onClick={() => handleChangeBrands(item)}>
                                                            {reactStringReplace(item, `${searchInput}`, (match, i) => (
                                                                <span key={i} style={{ fontWeight: "bold" }}>{match}</span>
                                                            ))}
                                                        </span>
                                                    ))}
                                                </div>
                                                <div className="category-items">
                                                    <h5 style={{ color: "grey", paddingLeft: "25px" }}>Top Categories</h5>
                                                    {categories && categories.map((item, index) => (
                                                        <span key={index} className="dropdown-item"
                                                            onClick={() => handleChangeCategories(item)}>
                                                            <div style={{ display: "flex" }}>
                                                                <div>
                                                                    {reactStringReplace(item.label, `${searchInput}`, (match, i) => (
                                                                        <span key={i} style={{ fontWeight: "bold" }}>{match.label}</span>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                        {
                                            searchInput &&
                                            <button onClick={(e) => cancelSearchItem(e)} className="mobile-button-cancel" >
                                                <Cancel />
                                            </button>
                                        }
                                        <button type="submit" className="mobile-button-submit">
                                            <Search />
                                        </button>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                }
            </Translation>
        )
    }
}

export default withRouter(MobileHeader)