//import config from "../config/config"
import { handleResponse } from "../../../../utils/handleResponse";
import { checkResponse } from "../../../../utils/checkResponse";
import config from "../../../../config/config";
import axios from 'axios'

export const Service = { autoSuggestion }

function autoSuggestion(input) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/products/search?q=${input}`)
            .then(handleResponse)
            .then(checkResponse)


    );
}
