import React from "react";
import Modal from "../../UI/Modal/Modal"
import Login from "../../../features/Login/Login"
import Categories from "../../../features/Categories/Categories";
import NewHeader from "./NewHeader/NewHeader";
import MobileHeader from "./MobileHeader/MobileHeader"
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux"
import { debounce } from "lodash"
import * as actionCreators from "../../../redux/store/actions"
import { ecommerceService } from "../../../services/ecommerce/EcommerceService";
import { Service } from "./NewHeader/Service";

class ResponsiveHeader extends React.Component {
    constructor(props) {
        super(props);
        this.inputFocus = this.utilizeFocus()
        this.state = {
            showLogin: false,
            showCategories: false,
            isOpen: false,
            searchInput: "",
            searchData: null,
            category: "",
            order: null,
            tips: null,
            categories: null,
        };
    }

    utilizeFocus = () => {
        const ref = React.createRef()
        const setFocus = () => {
            ref.current && ref.current.focus()
        }
        return { setFocus, ref }
    }

    componentDidMount() {
        if (this.props.user) {
            ecommerceService.orders(0)
                .then(response => {
                    if (response.data.length > 0) {
                        this.setState({
                            order: response.data[0],
                        })
                    }
                }).catch((error) => {
                    console.log(error);
                })
        }
        if (this.state.searchInput !== "") {
            Service.autoSuggestion(this.state.searchInput)
                .then(response => {
                    if (response.data && response.data.length > 0) {
                        this.setState({ tips: response.data.tips, categories: response.data.categories, brands: response.data.brands })
                    }
                }).catch(error => {
                    console.log(error)
                })
        }
        // const path = "html/body/div[@id='root']/div/div/div/div/div/div/div[2]/div[2]/form/input";
        // const input = document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
        // input.addEventListener("keydown", this.clickInput);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.searchInput !== this.state.searchInput) {
            this.getItems(this.state.searchInput);
        }

        if (this.props.location.key !== prevProps.location.key
            && this.props.location.pathname !== "/search") {
            this.setState({ searchInput: "" })
        }
    }

    handleInput = (e) => this.setState({ searchInput: e.target.value, isOpen: true });

    getSearchData = (searchInput) => {
        if (this.state.searchInput !== "") {
            Service.autoSuggestion(this.state.searchInput)
                .then(response => {
                    this.setState({ tips: response.data.tips, categories: response.data.categories, brands: response.data.brands })
                }).catch(error => {
                    console.log(error)
                })
        } else {
            this.setState({ tips: null, isOpen: false })
        }
    }

    getItems = debounce((searchInput) => {
        this.getSearchData(searchInput)
    }, 1000)

    omponentWillUnmount() {
        document.removeEventListener('click', this.globalClickListener)
    }

    globalClickListener = () => {
        this.setState({ isOpen: false }, () => {
            document.removeEventListener('click', this.globalClickListener)
        })
    }

    handleChangeTips = (item) => {
        this.setState({
            isOpen: false,
            searchInput: `${item}`
        })

        this.props.history.push({
            pathname: "/search",
            state: {
                searchInput: `${item}`,
            }
        })
    }
    handleChangeCategories = (item) => {
        this.setState({
            isOpen: false,
        })
        this.props.history.push({
            pathname: "/search",
            state: {
                searchInput: this.state.searchInput,
                //searchInput: this.state.tips[0],
                categoryId: `${item.categoryId}`
            }
        })
    }
    handleChangeBrands = (item) => {
        this.setState({
            isOpen: false,
            searchInput: `${item}`
        })

        this.props.history.push({
            pathname: "/search",
            state: {
                searchInput: `${item}`,
                brands: `${item.brands}`
            }
        })
    }

    cancelSearchItem = () => {
        this.setState({
            searchInput: "",
            isOpen: false
        });
        this.inputFocus.setFocus()
    }

    setCategory = (item) => this.setState({ category: item })

    closeAll = () => this.setState({
        showLogin: false,
        showAvailability: false,
        showSearch: false,
        showCategories: false,
    })

    loginModal = () => this.setState({
        showLogin: !this.state.showLogin,
        showAvailability: false,
        showSearch: false,
        showCategories: false,
    })

    categoriesModal = () => this.setState({
        showCategories: !this.state.showCategories,
        showLogin: false,
        showAvailability: false,
        showSearch: false,
    })

    submitSearch = (e) => {
        e.preventDefault();
        console.log(this.state);
        const path = "html/body/div[@id='root']/div/div/div/div/div/div/div[2]/div[2]/form/input";
        const input = document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
        this.setState({
            searchInput: input.value,
            isOpen: false
        }, () => {
            this.props.history.push({
                pathname: "/search",
                state: { searchInput: input.value,
                    categoryId: this.state.category ? this.state.category.id : "",
                    brands: this.state.brands
                }
            })
            this.closeAll();
        });
    }

    renderTotal = (cart) => {
        if (cart) {
            if (cart.netTotal) {
                return `${cart.netTotal.toFixed(2)} \u20AC`;
            } else {
                return `0.00 \u20AC`;
            }
        } else {
            return `0.00 \u20AC`;
        }
    }

    render() {
        const { user, showModalLogin } = this.props;
        // const path = "html/body/div[@id='root']/div/div/div/div/div/div/div[2]/div[2]/form/input";
        // console.log(document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue);
        return (
            !location.pathname.includes("/app/share") &&
            <>
                <div className="d-none d-lg-block">
                    <NewHeader
                        {...this.props}
                        closeAll={this.closeAll}
                        loginModal={this.loginModal}
                        categoriesModal={this.categoriesModal}
                        tips={this.state.tips}
                        categories={this.state.categories}
                        brands={this.state.brands}
                        renderTotal={this.renderTotal}
                        cancelSearchItem={this.cancelSearchItem}
                        inputFocus={this.inputFocus}
                        showCategories={this.state.showCategories}
                        showLogin={this.state.showLogin}
                        handleChangeTips={this.handleChangeTips}
                        handleChangeCategories={this.handleChangeCategories}
                        handleChangeBrands={this.handleChangeBrands}
                        searchInput={this.state.searchInput}
                        onChange={this.handleInput}
                        availability={this.props.availability}
                        showModalLogin={showModalLogin}
                        order={this.state.order}
                        submitSearch={this.submitSearch}
                        setCategory={this.setCategory}
                        isOpen={this.state.isOpen}
                    />
                </div>

                <div className="d-lg-none">
                    {!window.location.pathname.includes("/leaflets/") &&
                        <MobileHeader
                            {...this.props}
                            closeAll={this.closeAll}
                            loginModal={this.loginModal}
                            categoriesModal={this.categoriesModal}
                            tips={this.state.tips}
                            categories={this.state.categories}
                            brands={this.state.brands}
                            renderTotal={this.renderTotal}
                            cancelSearchItem={this.cancelSearchItem}
                            inputFocus={this.inputFocus}
                            showCategories={this.state.showCategories}
                            showLogin={this.state.showLogin}
                            handleChangeTips={this.handleChangeTips}
                            handleChangeCategories={this.handleChangeCategories}
                            handleChangeBrands={this.handleChangeBrands}
                            searchInput={this.state.searchInput}
                            onChange={this.handleInput}
                            availability={this.props.availability}
                            showModalLogin={showModalLogin}
                            order={this.state.order}
                            submitSearch={this.submitSearch}
                            setCategory={this.setCategory}
                            isOpen={this.state.isOpen}
                        />
                    }
                </div>
                {
                    this.state.showLogin &&
                    <Modal show={this.state.showLogin} close={this.loginModal}>
                        <Login user={user} closeAll={this.closeAll} />
                    </Modal>
                }
                {
                    this.state.showCategories &&
                    <Modal show={this.state.showCategories} close={this.categoriesModal}>
                        <Categories closeAll={this.closeAll} />
                    </Modal>
                }
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        availability: state.cart.availability,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showModalLogin: () => dispatch(actionCreators.showLogin(true)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResponsiveHeader));
