import React from "react"
import { useForm } from 'react-hook-form'
import { Translation } from 'react-i18next';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ValidateInput from "../../../components/UI/Input/ValidateInput";
import PasswordInput from "../../../components/UI/Input/PasswordInput";

import "../signup.styles.scss";

function SignupStep3(props) {
    const { register, handleSubmit, errors, watch } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        props.signup(data);
    }

    const { data } = props;

    // console.log(watch())
    // console.log(watch('errors'))
    return (
        <Translation>
            {t =>
                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
                    className={`signup needs-validation ${errors ? "had-validation" : ""}`}>
                    <div className="signup-content">
                        <div className="signup-card">
                            <Row>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="username"
                                        label={t("email")}
                                        type="email"
                                        watch={watch("username")}
                                        value={data && data.username}
                                        maxLength={100}
                                        register={register({
                                            required: t("required.field"),
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: t("error.invalidEmail")
                                            }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.username && errors.username.message}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="form-group">
                                    <PasswordInput
                                        name="password"
                                        label="password"
                                        type="password"
                                        watch={watch("password")}
                                        value={data && data.password}
                                        register={register({
                                            required: t("required.field"),
                                            minLength: { value: 8, message: t("checkPassword") }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.password && errors.password.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    <PasswordInput
                                        name="passwordConfirm"
                                        label="passwordConfirm"
                                        type="password"
                                        watch={watch("passwordConfirm")}
                                        value={data && data.passwordConfirm}
                                        register={register({
                                            required: t("required.field"),
                                            validate: {
                                                passwordMatch: value => value === watch('password')
                                            }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.passwordConfirm && errors.passwordConfirm.message}
                                        {errors.passwordConfirm && errors.passwordConfirm.type === "passwordMatch" && (
                                            <span>{t("signup.passwordsDoesNotMatch")}</span>
                                        )}
                                    </div>
                                </Col>
                                <Row className="container-fluid">
                                    <Col md={12} className="form-group">
                                        <small id="passwordHelp" className="form-text text-muted">
                                            {t("passwordChange.passwordHelp")}
                                        </small>
                                    </Col>
                                </Row>
                            </Row>
                            <hr className="hr" />
                            <div className="custom-control custom-checkbox">
                                <input className="custom-control-input" type="checkbox" id="accept1" name="accept1"
                                    ref={register({
                                        required: t("required.field"),
                                    })}
                                />
                                <label className="custom-control-label" htmlFor="accept1">
                                    {t("signup.acceptTermsAndConditions")}
                                </label>
                                <div className="form-control-invalid">
                                    {errors.accept1 && errors.accept1.message}
                                </div>
                            </div>
                        </div>
                        <div className="signup-actions">
                            <button className="secondary-cta" type="button" onClick={() => props.previousStep(watch())}>
                                <span> {`< ${t("goBack")}`}</span>
                            </button>
                            <button className="cta" type="submit">
                                <span>{t("signup.register")}</span>
                            </button>
                        </div>
                    </div>
                </form>
            }
        </Translation>
    );
}

export default SignupStep3;