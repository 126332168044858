import React from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import Row from "react-bootstrap/Row";
import Loader from 'react-loader-spinner'
import CardProduct from "../../components/Products/CardProduct/CardProduct";
import { Translation } from 'react-i18next';
import { ecommerceService } from "../../services/ecommerce/EcommerceService"
import { connect } from "react-redux"
import swal from "../../utils/swal";
import * as actionCreators from "../../redux/store/actions/index"

class NewSearch extends React.Component {
    constructor(props) {
        super(props);
        this.searchProducts = (input, categoryId, page) => {
            const storeId = this.props.cart ? this.props.cart.storeId : "";
            ecommerceService.searchProducts(input, storeId, page, categoryId, this.state.onlyPromotions, this.state.onlyBranded, "")
                .then(response => {
                    this.setState({
                        isLoaded: true,
                        products: response.data,
                        totalPages: response.totalPages,
                        page: response.number,
                        hasMore: !response.last
                    })
                }).catch((error) => {
                    if (error.status === 401 || error.code === "ERR_NETWORK"){
                        swal.warning("Your session has been timed out? You are kindly requested to login again.", "", () => this.props.openPopup())
                    }
                    console.log(error);
                })
        }
        this.state = {
            isLoaded: false,
            products: [],
            page: 0,
            totalPages: null,
            hasMore: true,
            onlyPromotions: this.props.location.state.onlyPromotions ? this.props.location.state.onlyPromotions : false,
            onlyBranded: false,
        }
    }

    componentDidMount() {
        console.log(this.props.location.state)
        const searchInput = this.props.location.state.searchInput;
        const categoryId = this.props.location.state.categoryId;
        this.searchProducts(searchInput, categoryId, this.state.page)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const newInput = nextProps.location.state.searchInput;
        const newCategoryId = nextProps.location.state.categoryId;
        if (newInput !== this.props.location.state.searchInput ||
            newCategoryId !== this.props.location.state.categoryId) {
            this.searchProducts(newInput, newCategoryId, 0)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.onlyPromotions !== prevState.onlyPromotions ||
            this.state.onlyBranded !== prevState.onlyBranded) {
            const searchInput = this.props.location.state.searchInput;
            const categoryId = this.props.location.state.categoryId;
            this.searchProducts(searchInput, categoryId, this.state.page)
        }
    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.checked });

    loadMore = () => {
        const page = this.state.page + 1;
        if (page < this.state.totalPages) {
            const storeId = this.props.cart ? this.props.cart.storeId : ""
            ecommerceService.searchProducts(this.props.location.state.searchInput, storeId, page,
                this.props.location.state.categoryId, this.state.onlyPromotions, this.state.onlyBranded, "")
                .then(response => {
                    const newResponse = this.state.products;
                    newResponse.push(...response.data);
                    this.setState({
                        products: newResponse,
                        totalPages: response.totalPages,
                        page: response.number,
                        hasMore: !response.last
                    })
                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    render() {
        const { products, hasMore, isLoaded } = this.state;
        return (
            <Translation>
                {t =>
                    <div className="mobile-view-search" style={{ backgroundColor: "#fbfbfb", minHeight: "300px" }}>
                        <div className="container-fluid search-product-view">
                            <div className="row h-100">
                                <div className="container-fluid max-product-row">
                                    <InfiniteScroll
                                        style={{ overflowX: "hidden" }}
                                        dataLength={products.length}
                                        next={this.loadMore}
                                        hasMore={hasMore}
                                        loader={<Loader
                                            type="ThreeDots"
                                            color="#333"
                                            height={70}
                                            width={70}
                                            timeout={3000} //3 secs
                                        />}
                                    >

                                        {/* <Row className="d-flex justify-content-center pb-3">
                                            <div className="col-sm-4 col-md-3 col-lg-2 ml-2 ml-sm-0 custom-checkbox">
                                                <input type="checkbox" className="custom-control-input"
                                                    value={this.state.onlyPromotions}
                                                    id="onlyPromotions" name="onlyPromotions"
                                                    onChange={this.onChange}
                                                />
                                                <label className="custom-control-label mobile-checkbox" htmlFor="onlyPromotions">
                                                    {t("onlyPromotions")}
                                                </label>
                                            </div>
                                            <div className="col-sm-4 col-md-3 col-lg-2 ml-2 ml-sm-0 custom-checkbox">
                                                <input type="checkbox" className="custom-control-input"
                                                    value={this.state.onlyBranded}
                                                    id="onlyBranded" name="onlyBranded"
                                                    onChange={this.onChange}
                                                />
                                                <label className="custom-control-label mobile-checkbox" htmlFor="onlyBranded">
                                                    {t("onlyBranded")}
                                                </label>
                                            </div>
                                        </Row> */}

                                        <Row className="d-flex justify-content-center">
                                            {products && products.length > 0 && products.map((item) => (
                                                <CardProduct key={item.id} item={item} onClick={this.props.onClick} />
                                            ))}
                                            {isLoaded && products && products.length === 0 &&
                                                <span className="title">
                                                    {t("newSearch.noProductsFound")}
                                                </span>
                                            }
                                        </Row>
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
    };
}

const mapDispatchToProps = dispatch => {
    return {
      openPopup: () => dispatch(actionCreators.showLogin(true)),
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(NewSearch);
